import React from 'react'
import TemplateWrapper from '../components/layout';

const NotFoundPage = () => (
  <TemplateWrapper>
  <div>
    <h1>Страница не найдена - ошибка 404</h1>
    <p>Вы попали на страницу которой не существует.</p>
  </div>
  </TemplateWrapper>
)

export default NotFoundPage
